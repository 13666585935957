<template>
  <div class="grid grid-cols-[130px_1fr] items-center gap-x-4 py-3">
    <h5 v-if="!hideTitle" class="leading-none">
      {{ $t('parcel.backorderStatus') }}
    </h5>
    <div class="flex h-7 items-center" :class="{ 'w-max': hideTitle }">
      <div
        v-show="status"
        class="inline-block size-5 rounded-full"
        :class="statusColor"
      />
      <p class="mb-0 ml-1 inline-block text-sm">
        {{ statusLabel }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ParcelProblemStatus } from '~/models'
const { parcelBackorderStatuses } = useParcelsData()

type Props = {
  status?: ParcelProblemStatus
  isProblematic: boolean
  hideTitle?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hideTitle: false
})

const statusLabel = computed((): string => {
  const status = props.status
  const isProblematic = props.isProblematic

  if (!status) {
    return '-'
  }

  if (status === 'solved' && isProblematic) {
    return parcelBackorderStatuses.problematic.label
  }

  const statusLabel =
    parcelBackorderStatuses[status as keyof typeof parcelBackorderStatuses]
      ?.label

  return statusLabel || status || '-'
})

const statusColor = computed((): string => {
  const status = props.status as keyof typeof parcelBackorderStatuses
  let color = parcelBackorderStatuses[status]?.color

  if (status === 'solved' && props.isProblematic) {
    color = parcelBackorderStatuses.problematic.color
  }

  return color ? `bg-${color}-500` : 'bg-signal-blue-500'
})
</script>
