<template>
  <div
    class="cursor-pointer rounded-lg border-2 bg-gray-100 p-3"
    :class="[
      status === optionSelected ? 'border-signal-blue-500' : 'border-gray-100',
      optionSelected && optionSelected !== status ? 'opacity-50' : 'opacity-100'
    ]"
    @click="emit('click')"
  >
    <h5 class="mb-0">{{ parcelStatuses[status]?.label }}</h5>
    <p class="mb-0 text-sm text-signal-blue-500">
      <template v-if="parcelStatuses[status]?.description">
        {{ parcelStatuses[status]?.description }}
      </template>
      <template v-else>
        {{ parcelStatuses[status]?.label }}
      </template>
      <slot />
    </p>
  </div>
</template>

<script lang="ts" setup>
const { parcelStatuses } = useParcelsData()

// eslint-disable-next-line
const { status, optionSelected } = defineProps<{
  status: string
  optionSelected?: string
}>()

const emit = defineEmits(['click'])
</script>
