import {
  getDocs,
  onSnapshot,
  orderBy,
  query,
  type DocumentData,
  type DocumentChange,
  type QuerySnapshot,
  where,
  Query,
  Timestamp
} from 'firebase/firestore'

import type {
  AppUser,
  BackorderActiveListeners,
  BackorderAppUserParcels,
  Parcel,
  BackorderStatusOptions
} from '~/models'

export default function useBackorders() {
  const { collection } = useFirebase()
  const { parcelStatusesOpen } = useParcelsData()
  const { getUserAndAproData } = useAppUsers()
  const { t } = useI18n()
  const { reportError } = useSentry()

  const { backorderFilter, dateType } = storeToRefs(useBackorderStore())

  const backorderStatusOptions = ref<BackorderStatusOptions[]>([
    {
      text: t('backorder.parcelProblemStatus.new'),
      value: 'new',
      isProblematic: true
    },
    {
      text: t('backorder.parcelProblemStatus.regressed'),
      value: 'regressed',
      isProblematic: true
    },
    {
      text: t('backorder.parcelProblemStatus.problematic'),
      value: 'solvedProblematic',
      isProblematic: true
    },
    {
      text: t('backorder.parcelProblemStatus.solved'),
      value: 'solved',
      isProblematic: false
    }
  ])

  const backorders = ref<BackorderAppUserParcels[]>([])
  const backordersLocal = ref<BackorderAppUserParcels[]>([])
  const loadingBackorders = ref(false)

  async function fetchBackorders() {
    loadingBackorders.value = true

    try {
      const backorderParcels = await getBackorderParcels()

      const uniqueUserIds = [
        ...new Set(backorderParcels.map((backorder) => backorder.UserId))
      ]

      let backordersData = await combineAllBackordersByUser(
        uniqueUserIds,
        backorderParcels
      )

      // Get latest ProcessingDate && latest ParcelItem.Created for each backorder (used for sorting)
      backordersData = getParcelDetailsProcessedAndCreated(backordersData)

      backorders.value = backordersLocal.value = backordersData
    } catch (error) {
      reportError('Error fetching backorders', { error })
    } finally {
      loadingBackorders.value = false
    }
  }

  async function getBackorderParcels() {
    const backorderQuery = getBackorderQuery()

    const { docs } = await getDocs(backorderQuery)

    return docs.map((doc: DocumentData) => ({
      ...doc.data(),
      ref: doc.ref,
      id: doc.id
    })) as Parcel[]
  }

  async function combineAllBackordersByUser(
    uniqueUserIds: string[],
    backorderParcels: Parcel[]
  ) {
    return (await Promise.all(
      uniqueUserIds.map(async (userId) => {
        const appUser = await getAppUser(userId)

        return {
          ...appUser,
          appUserId: appUser?.id,
          parcels: backorderParcels.filter(
            (backorderParcel) => backorderParcel.UserId === userId
          )
        }
      })
    )) as BackorderAppUserParcels[]
  }

  function getBackorderQuery(): Query {
    const filterByProblemStatus = _cloneDeep(
      backorderFilter.value.currentStatus
    )

    const baseBackorderQuery = query(
      collection('Parcels'),
      where('CurrentStatus', 'in', parcelStatusesOpen),
      orderBy('Created', 'desc')
    )

    const constraints = []

    if (
      filterByProblemStatus.length === 0 ||
      filterByProblemStatus.length === backorderStatusOptions.value.length
    ) {
      return query(
        baseBackorderQuery,
        where('ProblemStatus', 'in', ['new', 'solved', 'regressed'])
      )
    }

    const isProblematicConstraints = setIsProblematicWhereClauses()

    if (isProblematicConstraints?.length) {
      constraints.push(...isProblematicConstraints)
    }

    if (
      filterByProblemStatus.includes('solvedProblematic') &&
      !filterByProblemStatus.includes('solved')
    ) {
      filterByProblemStatus.push('solved')
    }

    return query(
      baseBackorderQuery,
      where('ProblemStatus', 'in', filterByProblemStatus ?? []),
      ...constraints
    )
  }

  function setIsProblematicWhereClauses() {
    const { currentStatus } = backorderFilter.value

    if (currentStatus.includes('regressed')) {
      return []
    }

    if (
      currentStatus.includes('solved') &&
      currentStatus.includes('solvedProblematic')
    ) {
      return []
    }

    if (currentStatus.includes('solved')) {
      return [where('IsProblematic', '==', false)]
    }

    if (currentStatus.includes('solvedProblematic')) {
      return [
        where('IsProblematic', '==', true),
        where('ProblemStatus', '==', 'solved')
      ]
    }
  }

  async function getAppUser(userId: string): Promise<AppUser | null> {
    const appUser = await getUserAndAproData(userId)
    if (appUser) {
      appUser.fullName = `${appUser.ProfileDataMap?.FirstName} ${appUser.ProfileDataMap?.LastName}`
    }
    return appUser
  }

  function getParcelDetailsProcessedAndCreated(
    backorders: BackorderAppUserParcels[]
  ) {
    backorders.forEach((backorder) => {
      let latestCreatedParcelItem: null | Timestamp = null
      let latestProcessedDate: null | Timestamp = null

      backorder.parcels.forEach((parcel: Parcel) => {
        if (parcel.ProcessingDate) {
          if (
            !latestProcessedDate ||
            parcel.ProcessingDate > latestProcessedDate
          ) {
            latestProcessedDate = parcel.ProcessingDate
          }
        }

        const minByCreated = _minBy(parcel.ParcelItems, 'Created')

        if (minByCreated) {
          latestCreatedParcelItem = minByCreated.Created
        }
      })

      backorder.latestProcessedDate = latestProcessedDate
      backorder.latestCreatedParcelItem = latestCreatedParcelItem
    })

    return backorders
  }

  const activeListeners = ref<BackorderActiveListeners>({})

  function expandRowBackorder(item: any) {
    const backorderIndex = backorders.value.findIndex((backorder: any) => {
      return backorder.id === item.data.id
    })

    const backorder = backorders.value[backorderIndex]
    const appUserId = backorder.appUserId

    const backorderQueryUser = query(
      collection('Parcels'),
      where('UserId', '==', appUserId),
      where('CurrentStatus', 'in', parcelStatusesOpen)
    )

    activeListeners.value[appUserId] = onSnapshot(
      backorderQueryUser,
      (snapshot: QuerySnapshot) => {
        snapshot.docChanges().forEach((change: DocumentChange) => {
          const parcelIndex = backorder.parcels.findIndex(
            (parcel) => parcel.id === change.doc.id
          )

          switch (change.type) {
            case 'added':
              backorder.parcels = handleParcelAdded({
                parcels: backorder.parcels,
                newParcel: change.doc.data() as Parcel,
                existingIndex: parcelIndex
              })
              break
            case 'modified':
              backorder.parcels = handleParcelModified(
                backorder.parcels,
                change.doc.data() as Parcel
              )
              break
            case 'removed':
              backorder.parcels = handleParcelRemoved(
                backorder.parcels,
                parcelIndex
              )
              break
          }

          if (backorders.value[backorderIndex]) {
            backorders.value[backorderIndex].parcels = backorder.parcels
          }
        })
      }
    )
  }

  function handleParcelAdded({
    parcels,
    newParcel,
    existingIndex
  }: {
    parcels: Parcel[]
    newParcel: Parcel
    existingIndex: number
  }): Parcel[] {
    if (existingIndex === -1) {
      parcels.push(newParcel)
    } else {
      parcels[existingIndex] = newParcel
    }

    return parcels
  }

  function handleParcelModified(
    parcels: Parcel[],
    modifiedParcel: Parcel
  ): Parcel[] {
    return parcels.map((parcel) => {
      return parcel.id === modifiedParcel.id ? modifiedParcel : parcel
    })
  }

  function handleParcelRemoved(
    parcels: Parcel[],
    removedIndex: number
  ): Parcel[] {
    if (removedIndex !== -1) {
      parcels.splice(removedIndex, 1)
    }
    return parcels
  }

  // Close all expanded rows and detach listeners
  function resetTable() {
    for (const backorderId of Object.keys(expandedRows.value)) {
      if (activeListeners.value[backorderId]) {
        activeListeners.value[backorderId]()
        delete activeListeners.value[backorderId]
      }
    }

    expandedRows.value = {}
  }

  function closeRowListener(item: any) {
    const index = backorders.value.findIndex((backorder: any) => {
      return backorder.id === item.data.id
    })

    const backorderId = backorders.value[index].id

    if (activeListeners.value[backorderId]) {
      // Detach snapshot listener
      activeListeners.value[backorderId]()
      delete activeListeners.value[backorderId]
    }
  }

  const expandedRows = ref<any>({})

  async function resetTableRow(
    slotProps: { data: any; index: number },
    parcelId: string
  ) {
    closeRowListener(slotProps)

    const backorderUserId = slotProps.data.id
    const backorderIndex = backorders.value.findIndex(
      (backorder: any) => backorder.id === backorderUserId
    )

    const parcelIndex = backorders.value[backorderIndex].parcels.findIndex(
      (backorderParcel) => backorderParcel.id === parcelId
    )

    backorders.value[backorderIndex].parcels.splice(parcelIndex, 1)

    const problematicParcelAmountForUser = backorders.value[
      backorderIndex
    ].parcels.filter((backorder) => backorder.ProblemStatus).length

    if (!problematicParcelAmountForUser) {
      removeExpand(backorders.value[backorderIndex].id)
      backorders.value.splice(backorderIndex, 1)
    }
  }

  function removeExpand(id: string) {
    expandedRows.value = Object.keys(expandedRows.value)
      .filter((rowId) => rowId !== id)
      .reduce((newObj, key) => {
        newObj[key] = expandedRows.value[key]
        return newObj
      }, {} as any)
  }

  // Detach all listeners when component is unmounted
  onUnmounted(() => {
    for (const listener in activeListeners.value) {
      activeListeners.value[listener]()
    }
  })

  return {
    activeListeners,
    backorders,
    backordersLocal,
    backorderStatusOptions,
    closeRowListener,
    dateType,
    expandedRows,
    expandRowBackorder,
    fetchBackorders,
    loadingBackorders,
    removeExpand,
    resetTable,
    resetTableRow
  }
}
