<template>
  <DataTable :value="parcelItemsSplit" :rows="100">
    <Column field="DisplayName" :header="$t('parcel.articles')">
      <template #body="{ data, index }">
        <div class="flex items-center">
          <input
            :id="`${data.DisplayName}-${index}`"
            :name="`${data.DisplayName}-${index}`"
            type="checkbox"
            class="mr-3 size-4 rounded border-gray-300 text-aubergine-500 focus:ring-aubergine-500"
            :checked="checkedParcelItem(data)"
            @input="itemChecked($event, index)"
          />
          <label :for="`${data.DisplayName}-${index}`" class="cursor-pointer">
            <template v-if="data.CancelledReason">
              {{ $t('general.cancelled') }}:
            </template>
            <span :class="{ 'line-through': data.CancelledReason }">
              {{ data.DisplayName }}
            </span>
          </label>
        </div>
      </template>
    </Column>
    <Column field="Created" :header="$t('parcel.registered')">
      <template #body="{ data: { CancelledReason, Created } }">
        <span :class="{ 'line-through': CancelledReason }">
          {{ formatTimestamp(Created) }}
        </span>
      </template>
    </Column>
    <Column field="ExpectedAmount" :header="$t('parcel.expectedAmount')">
      <template
        #body="{ data: { CancelledReason, ExpectedAmount, MedicationUnit } }"
      >
        <span :class="{ 'line-through': CancelledReason }">
          {{ ExpectedAmount }} {{ MedicationUnit }}
        </span>
      </template>
    </Column>
    <Column
      field="PickedAmount"
      :header="$t('parcel.filledAmount')"
      class="w-32"
    >
      <template
        #body="{
          data: { CancelledReason, PickedAmount, MedicationUnit },
          index
        }"
      >
        <span
          v-if="CancelledReason"
          :class="{ 'line-through': CancelledReason }"
        >
          {{ PickedAmount }}
        </span>
        <div v-else class="flex items-center">
          <WpInput
            v-model="parcelItemsSplit[index].PickedAmount"
            type="number"
            :min="0"
            only-numbers
            class="mr-1"
            @change="updateAmountToSplit(index)"
            @input="updateAmountToSplit(index)"
          />
          {{ MedicationUnit }}
        </div>
      </template>
    </Column>
    <Column
      field="AmountToSplit"
      :header="$t('parcel.amountToSplit')"
      class="w-32"
    >
      <template
        #body="{
          data: { CancelledReason, AmountToSplit, MedicationUnit },
          index
        }"
      >
        <span
          v-if="CancelledReason"
          :class="{ 'line-through': CancelledReason }"
        >
          {{ AmountToSplit }}
        </span>
        <div v-else class="flex items-center">
          <WpInput
            v-model="parcelItemsSplit[index].AmountToSplit"
            type="number"
            :min="0"
            only-numbers
            class="mr-1"
          />
          {{ MedicationUnit }}
        </div>
      </template>
    </Column>
    <Column field="BrandChange" :header="$t('parcel.brandChangeShort')">
      <template #body="{ data: { BrandChange, CancelledReason } }">
        <span :class="{ 'line-through': CancelledReason }">
          {{ BrandChange ? $t('general.yes') : $t('general.no') }}
        </span>
      </template>
    </Column>
  </DataTable>

  <WpAlert variant="info" class="mt-4">
    {{ $t('parcel.divideInformation') }}
  </WpAlert>

  <div class="mt-4 grid grid-cols-2 gap-4">
    <WpButton variant="dark-outline" @click="emit('close')">
      {{ $t('general.cancel') }}
    </WpButton>
    <WpButton
      type="submit"
      variant="signal-blue"
      :disabled="checkedParcelItems.length === 0"
      :loading="loading"
      @click="submitSplitParcel()"
    >
      {{ $t('parcel.divide') }}
    </WpButton>
  </div>
</template>

<script setup lang="ts">
import type { Parcel, ParcelItemSplit } from '~/models'
const { calculateAmountToSplit, splitParcels } = useParcels()
const { formatTimestamp } = useHelpers()

const props = defineProps<{
  parcel: Parcel
}>()

const emit = defineEmits(['close'])

const parcelItemsSplit = ref<ParcelItemSplit[]>([])

onBeforeMount(() => {
  parcelItemsSplit.value = props.parcel.ParcelItems.map((item) => ({
    ...item,
    AmountToSplit: calculateAmountToSplit({
      expectedAmount: item.ExpectedAmount,
      pickedAmount: item.PickedAmount
    })
  }))
})

const checkedParcelItems = ref<ParcelItemSplit[]>([])
function itemChecked(event: Event, index: number) {
  const checkBox = (event.target as HTMLInputElement).checked
  const parcelItem = parcelItemsSplit.value[+index]

  if (checkBox) {
    checkedParcelItems.value.push(parcelItem)
    checkParcelFullyPicked(parcelItem)
    return
  }

  checkedParcelItems.value = checkedParcelItems.value.filter(
    (item) => item !== parcelItem
  )
}

function checkParcelFullyPicked(parcelItem: ParcelItemSplit) {
  if (!parcelItem.PickedAmount) return
  if (parcelItem.PickedAmount >= parcelItem.ExpectedAmount) {
    parcelItem.AmountToSplit = parcelItem.ExpectedAmount
    parcelItem.PickedAmount = 0
  }
}

function checkedParcelItem(item: ParcelItemSplit) {
  return checkedParcelItems.value.includes(item)
}

function updateAmountToSplit(index: number) {
  const { PickedAmount = 0, ExpectedAmount } = parcelItemsSplit.value[index]
  parcelItemsSplit.value[index].AmountToSplit = Math.max(
    ExpectedAmount - PickedAmount,
    0
  )
}

const loading = ref(false)
/**
 * Split parcels
 * Update current parcel with unselected items and create new parcel with selected items
 */
function submitSplitParcel() {
  loading.value = true
  splitParcels({
    parcel: props.parcel,
    checkedParcelItems: checkedParcelItems.value,
    parcelItemsSplit: parcelItemsSplit.value
  }).then(() => {
    loading.value = false
    emit('close')
  })
}
</script>
