<template>
  <div class="mt-4 grid grid-cols-[auto_230px_40px] rounded bg-gray-100 p-3">
    <p class="font-baloo2 text-sm font-bold text-neutral-700">
      {{ $t('parcel.addItem') }}
    </p>
    <p class="font-baloo2 text-sm font-bold text-neutral-700">
      {{ $t('parcel.quantity') }}
    </p>
  </div>
  <form @submit.prevent="addParcelItem()">
    <div
      class="relative mt-2 grid grid-cols-[auto_240px_40px] items-center gap-x-3 py-3"
    >
      <WpInput
        v-model="DisplayName"
        name="DisplayName"
        rules="required"
        :placeholder="$t('parcel.medicineZINumber')"
        :show-error-message="false"
        :disabled="addItemLoading"
      />
      <WpInput
        v-model="ExpectedAmount"
        name="ExpectedAmount"
        type="number"
        rules="required|min_value:1"
        :min="1"
        :only-numbers="true"
        :show-error-message="false"
        :placeholder="$t('parcel.quantity')"
        :disabled="addItemLoading"
        @enter="addParcelItem()"
      />

      <WpButton
        type="submit"
        class="size-10 !p-0"
        circle
        :disabled="addItemLoading"
      >
        <Spinner v-if="addItemLoading" />

        <PlusIcon v-else fill-color="#ffffff" />
      </WpButton>
    </div>
  </form>
  <p v-if="showNotAddedNotice" class="mb-2 text-center text-sm text-red-400">
    {{ $t('parcel.medicineNotAdded') }}
  </p>
</template>

<script lang="ts" setup>
import { useForm } from 'vee-validate'
import type { MedicationAgreement, ParcelItem } from '~/models'

const { handleSubmit } = useForm()
const { baseParcelItemAdd } = useParcels()
const { getMedicineArticle, getMedicineHPK, getUnitAbbreviation } =
  useMedicines()
const { toast } = useHelpers()
const { getMedicationAgreement } = useMedicationAgreement()

const props = defineProps({ parcelUserId: { type: String, required: true } })

const emit = defineEmits(['add-parcel-item'])

const DisplayName = ref('')
const ExpectedAmount = ref()

const showNotAddedNotice = ref(false)
watch([DisplayName, ExpectedAmount], ([displayName, expectedAmount]) => {
  showNotAddedNotice.value = displayName !== '' || expectedAmount !== ''
})

const addItemLoading = ref(false)
const addParcelItem = handleSubmit(
  async (values, { resetForm }): Promise<void> => {
    addItemLoading.value = true

    const medicineArticle = await getMedicineArticle(+DisplayName.value)

    if (!medicineArticle) {
      addItemLoading.value = false
      toast.error(
        `Medicijn niet gevonden, voor ZI nummer: ${DisplayName.value}`
      )
      return
    }

    const [medicationAgreementSnapshot] = await getMedicationAgreement(
      props.parcelUserId,
      medicineArticle?.id
    )

    const medicationAgreement = medicationAgreementSnapshot?.data() as
      | MedicationAgreement
      | undefined

    const medicationPreference = medicationAgreement?.MedicationPreference

    let parcelItem: ParcelItem = {
      ...baseParcelItemAdd,
      DisplayName: DisplayName.value,
      LabelText: DisplayName.value,
      ExpectedAmount: +ExpectedAmount.value
    }
    if (medicineArticle) {
      parcelItem = {
        ...baseParcelItemAdd,
        DisplayName: medicineArticle.ArticleName,
        LabelText: medicineArticle.ArticleName,
        MedicineArticleRef: medicineArticle.ref,
        MedicineProductRef: medicineArticle.MedicineProductRef,
        OriginalArticleNumber: medicineArticle.ArticleNumber,
        ExpectedAmount: +ExpectedAmount.value
      }

      const medicineHPK = await getMedicineHPK(medicineArticle)
      if (medicineHPK) {
        parcelItem.HPK = medicineHPK
      }

      const medicationUnit = await getUnitAbbreviation(
        medicineArticle.MedicineProductRef
      )
      if (medicationUnit) {
        parcelItem.MedicationUnit = medicationUnit
      }
    }

    if (medicationAgreement) {
      parcelItem.MedicationAgreementId = medicationAgreement.id
    }

    if (medicationPreference) {
      parcelItem.MedicationPreferenceType = medicationPreference?.PreferenceType
    }

    addItemLoading.value = false
    emit('add-parcel-item', parcelItem)

    DisplayName.value = ''
    ExpectedAmount.value = ''
    const inputDisplayName = document.getElementById('DisplayName')
    if (inputDisplayName) inputDisplayName.focus()
    resetForm()
  }
)
</script>
