<template>
  <DataTable :value="updatedParcelItems" :rows="100">
    <Column field="DisplayName" :header="t('parcel.articles')">
      <template #body="{ data: { DisplayName, CancelledReason }, index }">
        <div class="flex items-center">
          <template v-if="!CancelledReason">
            <ParcelItemCancel
              @clicked="cancelParcelItemIndex = index"
              @cancel-parcel-item="cancelParcelItem($event)"
            />
            <span class="ml-2">{{ DisplayName }}</span>
          </template>
          <div v-else class="flex items-center">
            <ParcelItemRecover
              @clicked="recoverParcelItemIndex = index"
              @recover-parcel-item="recoverParcelItem()"
            />
            <span class="ml-2">
              {{ $t('general.cancelled') }}:
              <span class="line-through">
                {{ DisplayName }}
              </span>
            </span>
          </div>
        </div>
      </template>
    </Column>
    <Column field="Created" :header="t('parcel.registered')">
      <template #body="{ data: { Created, CancelledReason } }">
        <span :class="{ 'line-through': CancelledReason }">
          {{ formatTimestamp(Created) }}
        </span>
      </template>
    </Column>
    <Column
      field="ExpectedAmount"
      :header="t('parcel.expectedAmount')"
      class="w-32"
    >
      <template
        #body="{
          data: { CancelledReason, ExpectedAmount, MedicationUnit },
          index
        }"
      >
        <span v-if="CancelledReason" class="line-through">
          {{ ExpectedAmount }} {{ MedicationUnit }}
        </span>
        <div v-else class="flex items-center">
          <WpInput
            v-model="updatedParcelItems[index].ExpectedAmount"
            type="number"
            only-numbers
            class="mr-1 w-20"
          />
          {{ MedicationUnit }}
        </div>
      </template>
    </Column>
    <Column
      field="PickedAmount"
      :header="t('parcel.filledAmount')"
      class="w-32"
    >
      <template
        #body="{
          data: { CancelledReason, MedicationUnit, PickedAmount },
          index
        }"
      >
        <span v-if="CancelledReason" class="line-through">
          {{ PickedAmount }}
          <template v-if="PickedAmount">
            {{ MedicationUnit }}
          </template>
        </span>
        <div v-else class="flex items-center">
          <WpInput
            v-model="updatedParcelItems[index].PickedAmount"
            type="number"
            only-numbers
            class="mr-1 w-20"
          />
          {{ MedicationUnit }}
        </div>
      </template>
    </Column>
    <Column
      field="BrandChange"
      :header="t('parcel.brandChangeShort')"
      class="w-32"
    >
      <template #body="{ index, data: { CancelledReason } }">
        <WpInput
          v-if="!CancelledReason"
          v-model="updatedParcelItems[index].brandConversion"
          name="brandConversion"
          :placeholder="$t('parcel.medicineZINumber')"
          type="text"
          only-numbers
        />
      </template>
    </Column>
    <Column field="StockMedApp" :header="$t('backorder.stockMedApp')">
      <template #body="{ data }">
        <div class="flex items-center">
          <MaterialIcon
            v-if="
              checkOutOfStockUntilIsProblematic({
                parcelItem: data,
                parcelExpectedDeliveryDate: props.deliveryDate
              })
            "
            v-tooltip.top="$t('backorder.checkOutOfStockUntilIsProblematic')"
            icon="warning"
            icon-class="mr-2 inline-block"
            color="signal-orange-500"
          />
          {{ formatTimestamp(data.OutOfStockUntil) }}
        </div>
      </template>
    </Column>
    <Column field="DeliveryDeadline" :header="$t('backorder.deliveryDeadline')">
      <template #body="{ data }">
        <div class="flex items-center">
          <MaterialIcon
            v-if="
              checkDeliveryDeadlineIsProblematic({
                parcelItem: data,
                parcelExpectedDeliveryDate: props.deliveryDate
              })
            "
            v-tooltip.top="$t('backorder.deliveryDeadlineAfterDeliveryDate')"
            icon="warning"
            icon-class="mr-2"
            color="signal-orange-500"
          />
          <WpBadge
            class="cursor-pointer whitespace-nowrap"
            :variant="
              checkDeliveryDeadlineIsProblematic({
                parcelItem: data,
                parcelExpectedDeliveryDate: props.deliveryDate
              })
                ? 'signal-orange'
                : 'light-gray'
            "
            @click="deliveryDeadlineParcelItem = data"
          >
            {{
              data.DeliveryDeadline
                ? formatTimestamp(data.DeliveryDeadline)
                : '-'
            }}
            <PencilIcon :size="14" class="relative top-0.5 ml-1 inline-block" />
          </WpBadge>
        </div>
      </template>
    </Column>
    <Column field="actions">
      <template #body="{ index }">
        <DeleteIcon
          v-tooltip.top="$t('parcel.delete')"
          :size="16"
          class="cursor-pointer text-red-500 transition-colors hover:text-red-700"
          @click="deleteParcelItem(index)"
        />
      </template>
    </Column>
  </DataTable>

  <ParcelAddItemForm
    :parcel-user-id="parcelUserId"
    @add-parcel-item="addParcelItem"
  />

  <div class="mt-3 grid grid-cols-2 gap-2">
    <WpButton variant="light-outline" @click="stopEditMode()">
      {{ $t('general.cancel') }}
    </WpButton>
    <WpButton
      variant="signal-blue"
      :loading="loading"
      @click.once="updateParcelItems"
    >
      {{ $t('general.save') }}
    </WpButton>
  </div>

  <BackOrderChangeDateModal
    :parcel-item="deliveryDeadlineParcelItem"
    @close="deliveryDeadlineParcelItem = undefined"
    @update="updateDeliveryDeadline"
  />
</template>

<script lang="ts" setup>
import { Timestamp } from 'firebase/firestore'
import type { ParcelItem, ParcelItemEdit } from '~/models'
const { user } = storeToRefs(useUserStore())
const {
  checkParcelItemsCancelled,
  checkOutOfStockUntilIsProblematic,
  checkDeliveryDeadlineIsProblematic,
  updateParcel
} = useParcels()
const { getMedicineArticle, getMedicineHPK, getUnitAbbreviation } =
  useMedicines()
const { toast, formatTimestamp } = useHelpers()
const { t } = useI18n()

type Props = {
  deliveryDate: Timestamp
  parcelItems: ParcelItem[]
  parcelId: string
  parcelUserId: string
}

const props = withDefaults(defineProps<Props>(), {
  parcelItems: () => []
})

const updatedParcelItems = ref<ParcelItemEdit[]>(_cloneDeep(props.parcelItems))
const loading = ref(false)

function deleteParcelItem(parcelIndex: number): void {
  updatedParcelItems.value = updatedParcelItems.value.filter(
    (item) => item !== updatedParcelItems.value[parcelIndex]
  )
}

const cancelParcelItemIndex = ref()
function cancelParcelItem(cancelReason: string): void {
  updatedParcelItems.value[cancelParcelItemIndex.value].CancelledReason =
    cancelReason

  if (user.value.displayName) {
    updatedParcelItems.value[cancelParcelItemIndex.value].CancelledBy =
      user.value.displayName
  }
}

const recoverParcelItemIndex = ref()
function recoverParcelItem() {
  delete updatedParcelItems.value[recoverParcelItemIndex.value].CancelledBy
  delete updatedParcelItems.value[recoverParcelItemIndex.value].CancelledReason
}

function addParcelItem(parcelItem: ParcelItem) {
  updatedParcelItems.value.push(parcelItem)
}

async function updateParcelItems() {
  loading.value = true
  await Promise.all(
    updatedParcelItems.value.map(async (parcelItem) => {
      await changeMedicineBrand(parcelItem)

      // Ensure amounts are numbers
      if (parcelItem.ExpectedAmount) {
        parcelItem.ExpectedAmount = +parcelItem.ExpectedAmount
      }

      if (parcelItem.PickedAmount) {
        parcelItem.PickedAmount = +parcelItem.PickedAmount
      }

      if (parcelItem.DisplayName === parcelItem.LabelText) {
        return
      }
    })
  )

  const updateParcelValue = await checkParcelItemsCancelled(
    updatedParcelItems.value
  )

  await updateParcel(props.parcelId, updateParcelValue)

  toast.success(t('parcel.successfullyUpdated'))
  emit('stop-edit-mode')
  loading.value = false
}

async function changeMedicineBrand(
  parcelItem: ParcelItemEdit
): Promise<ParcelItem> {
  if (!parcelItem.brandConversion) {
    delete parcelItem.brandConversion

    return parcelItem
  }

  const medicineArticle = await getMedicineArticle(+parcelItem.brandConversion)
  if (!medicineArticle) {
    toast.error(
      `Medicijn niet gevonden, voor ZI nummer: ${parcelItem.brandConversion}`
    )
    delete parcelItem.brandConversion

    return parcelItem
  }

  parcelItem.BrandChange = true
  parcelItem.DisplayName = medicineArticle.ArticleName
  parcelItem.LabelText = medicineArticle.ArticleName
  parcelItem.MedicineArticleRef = medicineArticle.ref
  parcelItem.MedicineProductRef = medicineArticle.MedicineProductRef
  parcelItem.OriginalArticleNumber = medicineArticle.ArticleNumber

  console.warn(
    `Emptying OOU because of brandchange for parcelItem: ${parcelItem.OriginalArticleNumber}, Done by: ${user.value.displayName}`
  )

  delete parcelItem.OutOfStockUntil
  delete parcelItem.OutOfStockOverwrittenBy
  delete parcelItem.MedicationPreferenceType

  const medicineHPK = await getMedicineHPK(medicineArticle)
  if (medicineHPK) {
    parcelItem.HPK = medicineHPK
  }

  const medicationUnit = await getUnitAbbreviation(
    medicineArticle.MedicineProductRef
  )
  if (medicationUnit) {
    parcelItem.MedicationUnit = medicationUnit
  }

  delete parcelItem.brandConversion

  return parcelItem
}

const emit = defineEmits(['stop-edit-mode'])
function stopEditMode(): void {
  updatedParcelItems.value = [...props.parcelItems]

  emit('stop-edit-mode')
}

const deliveryDeadlineParcelItem = ref<ParcelItem>()

function updateDeliveryDeadline(deliveryDeadline: Timestamp) {
  const index = updatedParcelItems.value.findIndex(
    (item) => item === deliveryDeadlineParcelItem.value
  )

  updatedParcelItems.value[index].DeliveryDeadline = deliveryDeadline

  deliveryDeadlineParcelItem.value = undefined
}
</script>
