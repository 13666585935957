<template>
  <WpModal
    v-model:open="backorderChangeDateShowModal"
    :title="backorderPlaceholder"
    size="sm"
    @close-modal="emits('close')"
  >
    <p class="mb-2 block">
      <strong>{{ t('backorder.medicine') }}:</strong>
      {{ backorderParcelItem?.DisplayName }}
    </p>
    <Datepicker
      v-model="dateToUpdate"
      name="datepicker"
      :placeholder="
        $t(
          `backorder.${dateType == 'deliveryDeadline' ? 'deliveryDeadline' : 'stockMedApp'}`
        )
      "
      :enable-time-picker="false"
      :clearable="false"
      format="dd-MM-yyyy"
      auto-apply
      locale="nl"
      required
    />
    <div
      v-if="dateType === 'deliveryDeadline' || dateType === 'outOfStockUntil'"
      class="ml-4 mt-4 flex items-center"
    >
      <input
        :id="'checkbox'"
        v-model="checkbox"
        :name="'checkbox'"
        type="checkbox"
        class="mr-3 size-4 rounded border-gray-300 text-aubergine-500 focus:ring-aubergine-500"
      />
      <label :for="'checkbox'">
        {{ checkboxLabel }}
      </label>
    </div>
    <div class="mt-4 grid grid-cols-2 gap-4">
      <WpButton variant="dark-outline" @click="emits('close')">
        {{ $t('general.cancel') }}
      </WpButton>
      <WpButton
        variant="signal-blue"
        :disabled="!dateToUpdate || !changeDetected"
        @click="submit()"
      >
        {{ $t('general.save') }}
      </WpButton>
    </div>
  </WpModal>
</template>

<script lang="ts" setup>
import { Timestamp } from 'firebase/firestore'
import type { ParcelItem } from '~/models'
const { formatTimestampMonthDayYear } = useHelpers()
const { t } = useI18n()
const { dateType } = useBackorders()

const props = defineProps<{
  parcelItem?: ParcelItem
}>()

const emits = defineEmits(['close', 'update'])

const checkbox = ref<boolean>(false)
const backorderChangeDateShowModal = ref(false)
const backorderParcelItem = ref<ParcelItem | null>()
const changeDetected = ref(false)
const dateToUpdate = ref<Date | null | Timestamp>()
const originalDate = ref<null | Timestamp>()

const backorderPlaceholder = computed((): string =>
  t(
    `backorder.${dateType.value == 'deliveryDeadline' ? 'deliveryDeadline' : 'stockMedApp'}`
  )
)

const checkboxLabel = computed(() => {
  if (dateType.value === 'deliveryDeadline') {
    return t('backorder.applyToAllItems')
  } else if (dateType.value === 'outOfStockUntil') {
    return t('backorder.clear')
  }
  return ''
})

onUnmounted(() => {
  dateToUpdate.value = null
})

watchArray(
  () => [dateToUpdate.value, checkbox.value],
  () => {
    if ((!originalDate.value && dateToUpdate.value) || checkbox.value) {
      changeDetected.value = true
    } else if (
      originalDate.value &&
      dateToUpdate.value &&
      originalDate.value.seconds !=
        Timestamp.fromDate(dateToUpdate.value as Date).seconds
    ) {
      changeDetected.value = true
    } else {
      changeDetected.value = false
    }
  }
)

watch(
  () => props.parcelItem,
  (parcelItem) => {
    originalDate.value = props.parcelItem?.DeliveryDeadline
    if (dateType.value == 'outOfStockUntil') {
      originalDate.value = props.parcelItem?.OutOfStockUntil
    }

    if (parcelItem) {
      openUpdateDateModal(parcelItem)
    } else {
      backorderChangeDateShowModal.value = false
      backorderParcelItem.value = null
      dateToUpdate.value = null
    }
  }
)

function openUpdateDateModal(parcelItem: ParcelItem) {
  backorderParcelItem.value = parcelItem
  let date = parcelItem.DeliveryDeadline
  if (dateType.value == 'outOfStockUntil') {
    date = parcelItem.OutOfStockUntil
  }
  dateToUpdate.value = formatTimestampMonthDayYear(date)
  backorderChangeDateShowModal.value = true
}

function submit() {
  let date = null
  if (dateToUpdate.value) {
    date = Timestamp.fromDate(dateToUpdate.value as Date)
  }

  emits('update', date, checkbox.value)
  checkbox.value = false
}
</script>
