<template>
  <div class="grid grid-cols-2 items-center py-2">
    <h5>
      {{ $t('parcel.expectedDeliveryDate') }}
    </h5>
    <div class="flex items-center">
      <template v-if="backorder">
        <AlertIcon
          v-if="isProblematic"
          class="mr-2 inline-block text-signal-orange-500"
        />
        <WpBadge
          :variant="isProblematic ? 'signal-orange' : 'signal-blue'"
          cursor-pointer
          @click="modalOpen = !modalOpen"
        >
          <span>{{ deliveryDateCurrent || $t('parcel.addDeliveryDate') }}</span>
          <PencilIcon :size="14" class="relative top-0.5 ml-2 inline-block" />
        </WpBadge>
      </template>

      <WpBadge
        v-else
        variant="signal-blue"
        cursor-pointer
        @click="modalOpen = !modalOpen"
      >
        <span>{{ deliveryDateCurrent || $t('parcel.addDeliveryDate') }}</span>
        <PencilIcon :size="16" class="relative top-0.5 ml-2 inline-block" />
      </WpBadge>

      <SpeedometerIcon
        v-if="urgentReason"
        v-tooltip.bottom="{ value: urgentTooltip, escape: false }"
        class="ml-3 hidden cursor-help text-signal-orange-500"
      />
    </div>

    <WpModal
      :open="modalOpen"
      :title="$t('parcel.deliveryDate')"
      @close-modal="closeModal()"
    >
      <div class="mb-8 grid grid-cols-2 gap-4">
        <StatusOption
          v-for="status in statuses"
          :key="status"
          :status="status"
          :option-selected="activeStatus"
          @click="activeStatus = status"
        >
          <p
            v-if="
              ['sendLater', 'delayed', 'changeDeliveryDate'].includes(status)
            "
            class="font-bold"
          >
            {{ $t('backorder.enterADate') }}
          </p>
          <p v-else>
            {{ $t('general.doYouKnowADate') }}
            <span class="font-bold">{{ $t('backorder.enterADate') }}</span>
          </p>
        </StatusOption>
      </div>
      <form @submit.prevent="updateDeliveryDate()">
        <label class="mb-2 inline-block font-bold">
          {{ $t('backorder.on') }}
        </label>
        <Datepicker
          v-model="editDeliveryDate"
          name="datepicker"
          :enable-time-picker="false"
          :min-date="new Date()"
          :action-row="{ showNow: true, showPreview: false }"
          format="dd-MM-yyyy"
          auto-apply
          locale="nl"
          :config="{ keepActionRow: true }"
        >
          <template #action-buttons>
            <button type="button" @click="editDeliveryDate = new Date()">
              {{ $t('date.today') }}
            </button>
          </template>
        </Datepicker>
        <div class="mt-8 grid grid-cols-2 gap-4">
          <WpButton variant="dark-outline" type="button" @click="closeModal()">
            {{ $t('general.cancel') }}
          </WpButton>
          <WpButton
            v-if="newDeliveryDateRequired"
            type="submit"
            variant="signal-blue"
            :disabled="!editDeliveryDate"
          >
            {{ $t('backorder.changeDate') }}
          </WpButton>
          <WpButton
            v-else
            :disabled="!activeStatus || !editDeliveryDate"
            type="submit"
            variant="signal-blue"
          >
            {{ $t('general.save') }}
          </WpButton>
        </div>
      </form>
    </WpModal>
  </div>
</template>

<script lang="ts" setup>
import { Timestamp, arrayUnion } from 'firebase/firestore'
import type { ParcelCurrentStatus } from '~/enums/ParcelCurrentStatus'
const {
  formatTimestamp,
  dateDayMonthYear,
  formatTimestampMonthDayYear,
  toast
} = useHelpers()
const { updateParcel } = useParcels()
const { t } = useI18n()
const { user } = storeToRefs(useUserStore())
const { reportError } = useSentry()

const props = defineProps<{
  backorder?: boolean
  deliveryDate: Timestamp
  hideActionButtons?: boolean
  isProblematic?: boolean
  parcelId: string
  urgentReason?: string
  urgentType?: string
}>()

const modalOpen = ref(false)

const deliveryDateCurrent = ref(formatTimestamp(props.deliveryDate))

watch(
  () => props.deliveryDate,
  (newVal) => {
    deliveryDateCurrent.value = formatTimestamp(newVal)
    editDeliveryDate.value = formatTimestampMonthDayYear(newVal)
  }
)

const urgentTooltip = computed(() => {
  if (!props.urgentReason) return ''

  return `${t(`parcel.${props.urgentType}UrgentType`)} <hr class="my-2" />  ${
    props.urgentReason
  }`
})

const editDeliveryDate = ref()

const activeStatus = ref<ParcelCurrentStatus>()
const statuses = ref<ParcelCurrentStatus[]>([
  'sendLater',
  'delayed',
  'prescriptionDelay',
  'changeDeliveryDate'
])

const newDeliveryDateRequired = computed(() => {
  if (!activeStatus.value) return false
  return ['sendLater', 'delayed', 'changeDeliveryDate'].includes(
    activeStatus.value
  )
})

function closeModal() {
  modalOpen.value = false
  activeStatus.value = undefined
}

type UpdateValues = {
  CurrentStatus?: ParcelCurrentStatus
  CurrentStatusLabel?: string
  DeliveryDate?: Timestamp
}
async function updateDeliveryDate() {
  try {
    let updateValues: UpdateValues = {}

    if (activeStatus.value !== 'changeDeliveryDate') {
      updateValues.CurrentStatus = activeStatus.value
      updateValues.CurrentStatusLabel = t(`parcel.${activeStatus.value}`)
    }

    if (editDeliveryDate.value) {
      updateValues.DeliveryDate = editDeliveryDate.value
      deliveryDateCurrent.value = dateDayMonthYear(editDeliveryDate.value)
    }

    await updateParcel(props.parcelId, {
      ...updateValues,
      StatusHistory: arrayUnion({
        Actor: `${user.value.displayName}`,
        Created: new Date(),
        Status: activeStatus.value
      })
    })

    closeModal()
    toast.success(t('parcel.deliveryDateUpdated'))
  } catch (error) {
    reportError('Something went wrong while updating parcel', {
      error,
      parcelId: props.parcelId
    })

    toast.error(t('backorder.deliveryDateUpdateFailed'))
  }
}
</script>
