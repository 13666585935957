export const useBackorderStore = defineStore('backorder', () => {
  const dateType = ref<'deliveryDeadline' | 'outOfStockUntil'>()

  const backorderFilter = reactive({
    currentStatus: ['new'] as string[]
  })

  return {
    backorderFilter,
    dateType
  }
})
