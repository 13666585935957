<template>
  <MaterialIcon
    v-tooltip.top="$t('parcel.delete')"
    icon="delete"
    @click="modalOpen = !modalOpen"
  />

  <WpModal v-model:open="modalOpen" :title="$t('parcel.delete')">
    <p>
      {{ $t('parcel.areYouSure') }} <strong> {{ parcelId }}</strong>
      {{ $t('parcel.deleting') }}
    </p>
    <div class="mt-4 grid grid-cols-2 gap-4">
      <WpButton variant="dark-outline" @click="modalOpen = !modalOpen">
        {{ $t('general.cancel') }}
      </WpButton>
      <WpButton :loading="loading" @click="removeParcel()">
        {{ $t('parcel.delete') }}
      </WpButton>
    </div>
  </WpModal>
</template>

<script lang="ts" setup>
const { deleteParcel } = useParcels()
const { toast } = useHelpers()
const { t } = useI18n()

const { parcelId } = defineProps<{
  parcelId: string
}>()

const modalOpen = ref(false)
const loading = ref(false)

function removeParcel() {
  loading.value = true
  deleteParcel(parcelId).then(() => {
    modalOpen.value = false
    loading.value = false
    toast.success(t('parcel.deleted'))
  })
}
</script>
