<template>
  <DataTable :value="parcelItems" :rows="100">
    <Column field="DisplayName" :header="t('parcel.articles')">
      <template #body="{ data, index }">
        <div class="flex items-center">
          <input
            :id="`${data.DisplayName}-${index}`"
            :name="`${data.DisplayName}-${index}`"
            type="checkbox"
            class="mr-3 size-4 rounded border-gray-300 text-aubergine-500 focus:ring-aubergine-500"
            :checked="checkedParcelItem(data)"
            @input="itemChecked($event, index)"
          />
          <label :for="`${data.DisplayName}-${index}`" class="cursor-pointer">
            <template v-if="data.CancelledReason">
              {{ $t('general.cancelled') }}:
            </template>
            <span :class="{ 'line-through': data.CancelledReason }">
              {{ data.DisplayName }}
            </span>
          </label>
        </div>
      </template>
    </Column>
    <Column field="Created" :header="t('parcel.registered')">
      <template #body="{ data: { CancelledReason, Created } }">
        <span :class="{ 'line-through': CancelledReason }">
          {{ formatTimestamp(Created) }}
        </span>
      </template>
    </Column>
    <Column field="ExpectedAmount" :header="t('parcel.expectedAmount')">
      <template
        #body="{ data: { CancelledReason, ExpectedAmount, MedicationUnit } }"
      >
        <span :class="{ 'line-through': CancelledReason }">
          {{ ExpectedAmount }} {{ MedicationUnit }}
        </span>
      </template>
    </Column>
    <Column field="PickedAmount" :header="t('parcel.filledAmount')">
      <template
        #body="{ data: { CancelledReason, PickedAmount, MedicationUnit } }"
      >
        <span :class="{ 'line-through': CancelledReason }">
          {{ PickedAmount }}
          <template v-if="PickedAmount">{{ MedicationUnit }}</template>
        </span>
      </template>
    </Column>
    <Column field="BrandChange" :header="t('parcel.brandChangeShort')">
      <template #body="{ data: { BrandChange, CancelledReason } }">
        <span :class="{ 'line-through': CancelledReason }">
          {{ BrandChange ? $t('general.yes') : $t('general.no') }}
        </span>
      </template>
    </Column>
  </DataTable>

  <ParcelMergeTo
    :parcel="parcel"
    :parcel-items="parcel.ParcelItems"
    :checked-parcel-items="checkedParcelItems"
    @close="emit('close')"
  />
</template>

<script setup lang="ts">
import type { Parcel, ParcelItem } from '~/models'
const { parcelsOpen } = storeToRefs(useSearchParcel())
const { formatTimestamp } = useHelpers()
const { t } = useI18n()

const props = defineProps<{
  parcel: Parcel
  parcelId: string
  parcelItems: ParcelItem[]
}>()

const otherUserParcels = ref<Parcel[]>([])

const emit = defineEmits(['close'])
const checkedParcelItems = ref<ParcelItem[]>([])
function itemChecked(event: Event, index: number) {
  const checkBox = (event.target as HTMLInputElement).checked
  if (checkBox) {
    checkedParcelItems.value.push(props.parcelItems[+index])
    return
  }
  checkedParcelItems.value = checkedParcelItems.value.filter(
    (item) => item !== props.parcelItems[+index]
  )
}

const checkedParcelItem = (item: ParcelItem) =>
  checkedParcelItems.value.includes(item)

onMounted(async () => {
  otherUserParcels.value = parcelsOpen.value.filter(
    (parcel: Parcel) => parcel.id !== props.parcel.id
  )
})
</script>
