<template>
  <div>
    <div class="mb-4 flex items-center">
      <MaterialIcon v-if="!collapsable" icon="package" color="aubergine-900" />
      <MaterialIcon
        v-if="collapsable && collapsed"
        icon="expand_less"
        color="aubergine-900"
        @click="emit('collapsed')"
      />
      <MaterialIcon
        v-if="collapsable && !collapsed"
        icon="expand_more"
        color="aubergine-900"
        @click="emit('collapsed')"
      />

      <p
        class="ml-4 grow text-2xl text-aubergine-900"
        :class="{ 'cursor-pointer': collapsable }"
        @click="collapsable ? (collapsed = !collapsed) : ''"
      >
        <span class="font-baloo2">
          {{ parcelSplit ? $t('parcel.splitParcel') : $t('parcel.parcel') }}:
        </span>
        {{ parcel.id }}
      </p>

      <div v-if="!hideActionButtons" class="parcel-menu-icons flex space-x-8">
        <MaterialIcon
          v-tooltip.top="$t('parcel.edit')"
          icon="edit"
          icon-class="hover:text-zinc-500"
          @click="emit('edit')"
        />
        <MaterialIcon
          v-tooltip.top="$t('parcel.split')"
          icon="call_split"
          icon-class="hover:text-zinc-500"
          @click="emit('split')"
        />
        <MaterialIcon
          v-tooltip.top="$t('parcel.merge')"
          icon="call_merge"
          icon-class="hover:text-zinc-500"
          @click="emit('merge')"
        />
        <ParcelDelete v-if="!hideDeleteButton" :parcel-id="parcel.id" />
      </div>
    </div>

    <div v-if="view === 'full'" class="grid grid-cols-3 border-t py-2">
      <ParcelShipment
        :shipment-method="parcel?.ShipmentMethod as ShipmentTypeEnum"
      />
      <ParcelTrackAndTrace
        :t-and-t-code="parcel?.TAndTCode"
        :t-and-t-link="parcel?.TAndTLink"
        class="mr-4"
      />
      <ParcelStorageLocation :location="parcel?.StorageLocation" />
    </div>

    <div class="grid grid-flow-col grid-cols-3 border-t py-2">
      <ParcelShipmentStatus :status="parcel?.CurrentStatus" />
      <ParcelCreatedDate :parcel="parcel" />
      <ParcelDeliveryDate
        :parcel-id="parcel.id"
        :delivery-date="parcel?.DeliveryDate"
        :urgent-reason="parcel?.UrgentReason"
        :urgent-type="parcel?.UrgentType"
        :is-problematic="parcel?.IsProblematic"
        :backorder="isBackorder"
      />
    </div>

    <div class="grid grid-flow-col grid-cols-3 border-t py-2">
      <ParcelBackorderStatus
        :status="parcel?.ProblemStatus"
        :is-problematic="parcel?.IsProblematic ?? false"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Parcel, ShipmentTypeEnum } from '~/models'

type Props = {
  collapse?: boolean
  collapsable?: boolean
  hideActionButtons?: boolean
  hideDeleteButton?: boolean
  parcel: Parcel
  isBackorder?: boolean
  parcelSplit?: boolean
  view?: 'simple' | 'full'
}

const props = withDefaults(defineProps<Props>(), {
  view: 'full'
})

const emit = defineEmits(['edit', 'collapsed', 'split', 'merge'])

const collapsed = ref(false)
onMounted(() => {
  if (props.collapsable) collapsed.value = true
})

watch(
  () => props.collapse,
  (value: boolean) => {
    collapsed.value = value
  }
)
</script>
