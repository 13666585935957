<template>
  <div class="grid grid-cols-2 items-center py-2">
    <h5>{{ $t('parcel.createdDate') }}</h5>
    <div class="flex items-center">
      <WpBadge>
        {{ formatTimestamp(parcel.Created) }}
      </WpBadge>

      <MaterialIcon
        v-if="isOlderThanTwoWeeksAndIsBackorder()"
        v-tooltip.top="$t('parcel.olderThanTwoWeeks')"
        icon="warning"
        icon-class="ml-1"
        color="orange-500"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { isBefore, subWeeks } from 'date-fns'
import { Parcel } from '~/models'
const { formatTimestamp } = useHelpers()
const { parcelStatusesOpen } = useParcelsData()

const { parcel } = defineProps<{
  parcel: Parcel
}>()

function isOlderThanTwoWeeksAndIsBackorder() {
  if (!parcel?.ProblemStatus) return false

  if (!['new', 'solved', 'regressed'].includes(parcel.ProblemStatus)) {
    return false
  }

  if (!parcelStatusesOpen.includes(parcel.CurrentStatus)) {
    return false
  }

  if (parcel.ProblemStatus === 'solved' && !parcel.IsProblematic) {
    return false
  }

  return isBefore(
    new Date(parcel.Created.seconds * 1000),
    subWeeks(new Date(), 2)
  )
}
</script>
