<template>
  <UPopover :ui="{ popper: { placement: 'top' } }" class="flex">
    <CloseCircleIcon
      v-tooltip.top="$t('general.cancel')"
      :size="16"
      class="cursor-pointer text-signal-orange-500 transition-colors hover:text-signal-orange-600"
      @click="emit('clicked')"
    />

    <template #panel="{ close }">
      <h3 class="mb-3 text-lg font-semibold text-aubergine-900">
        {{ $t('parcel.cancelParcelItem') }}
      </h3>
      <WpSelect
        v-model="parcelItemCancelReason"
        :options="parcelItemCancelReasons"
        :selected-value="parcelItemCancelReason"
        select-class="!mt-0 w-24 pt-[10px] pb-[10px]"
      />
      <div class="mt-6 grid grid-cols-2 gap-4">
        <WpButton variant="dark-outline" @click="close">
          {{ $t('general.cancel') }}
        </WpButton>
        <WpButton
          :disabled="!parcelItemCancelReason"
          variant="signal-blue"
          @click="emit('cancelParcelItem', parcelItemCancelReason)"
        >
          {{ $t('general.save') }}
        </WpButton>
      </div>
    </template>
  </UPopover>
</template>

<script setup lang="ts">
const { parcelItemCancelReasons } = useParcelsData()

const emit = defineEmits(['clicked', 'cancelParcelItem'])

const parcelItemCancelReason = ref('')
</script>
