<template>
  <div class="group grid grid-cols-[130px_1fr_60px] items-center gap-x-4 py-2">
    <h5>{{ $t('parcel.shippingMethod') }}</h5>
    <p class="mb-0 text-gray-400">
      {{ getShipmentTypeLabel(shipmentMethod!) }}
    </p>
    <span
      class="ml-auto flex justify-items-end opacity-0 transition-opacity group-hover:opacity-50"
    >
    </span>
  </div>
</template>

<script lang="ts" setup>
import type { ShipmentTypeEnum } from '~/models'
const { getShipmentTypeLabel } = useShipment()

defineProps<{
  shipmentMethod: ShipmentTypeEnum | '' | null
}>()
</script>
